<template>
  <div>
    <v-container class="grey lighten-5" style="font-weight:400;">
      <v-row no-gutters v-for="(item, index) in this.leaderboard" :key="index">
        <v-col cols="12" sm="12" class="hover">
          <div
              class="lider-item"
              :class="{border: index == 6, 'current-team':item.teamName === team.teamName}"
          >
            <div class="item-id" :class="{paddingNo: !item.description}">{{ index + 1 }}</div>
            <div class="item-team-container">
              <div class="team-container">
                <div class="item-name" :class="{bold: item.description}">{{ item.teamName }}</div>
                <div
                    class="item-pts"
                    :class="{bold: item.description}"
                >{{ (item.score || "0").toLocaleString() }}pts
                </div>
              </div>
              <div class="team-container" v-if="index<7">
                <div class="item-description">{{ item.leaderboardTitle || item.supporting }}</div>
                <!-- <div v-if="(index > 4 && index < 7)" class="free"></div> -->
                <div
                    class="item-price"
                    v-if="donates.length>index"
                >${{ donates[index].toLocaleString() }}
                </div>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState(["leaderboard", "donates", "userId", "team"])
  },
  methods: {
    ...mapActions(["refreshLeaderboard"])
  },
  created: function () {
    window.history.replaceState(
        {},
        "FunFunding",
        `/?teamId=${this.team._id}&refUserId=${this.userId}`
    );
    this.refreshLeaderboard();
  }
};
</script>

<style scoped>
.current-team {
  background-color: #edf5fe;
}

.lider-item {
  display: flex;
  width: 100%;
  padding: 9px 0;
  position: relative;
}

.hover {
  transition: 0.4s;
}

.hover:hover {
  background-color: #edf5fe;
  cursor: pointer;
}

.border {
  border-bottom: 1px solid #e0e0e0;
}

.paddingNo {
  padding-top: 0 !important;
}

.free {
  width: 50px;
  height: 20px;
  background-image: url("../assets/images/free.png");
  background-size: cover;
}

.team-container {
  display: flex;
  justify-content: space-between;
}

.item-team-container {
  width: 90%;
}

.item-id {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  color: #535bb6;
  width: 10%;
}

.item-pts {
  font-family: Montserrat;
  font-size: 16px;
  color: #535bb6;
  font-weight: 600;
}

.item-name {
  margin-bottom: 8px;
  font-family: Montserrat;
  font-size: 16px;
  color: #535bb6;
  font-weight: 600;
}

.item-name.bold {
  font-weight: bold;
  font-size: 20px;
}

.item-pts.bold {
  font-size: 20px;
  font-weight: bold;
}

.item-id {
  font-size: 14px;
  padding: 5px;
}

.item-description {
  font-family: Montserrat;
  font-size: 14px;
  font-weight: bold;
  color: #88bdf4;
  line-height: 1.4;
  padding-top: 6px;
}

.item-price {
  font-family: Montserrat;
  font-size: 20px;
  font-weight: bold;
  color: #88bdf4;
}
</style>
