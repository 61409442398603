<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import store from "./store";

let globalUrl = null;

let loading  = function () {
};
let playGame = function (url) {
  globalUrl = url;
  //here's the function that tell the game starts once loaded
  //you can fire this function, for istance, after you've verified that the user is logged.
  window.gameIframe.contentWindow.postMessage(
      {
        message: "callbackExecuted"
      },
      globalUrl
  );
};

window.funfunding        = window.funfunding || {};
window.funfunding.uuidv4 = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
        v = c == "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

let sendScore = function (object) {
  const {type} = object;
  if (type === "level_complete") return;
  if (type !== "game_over") {
    window.alert(`New Score:${JSON.stringify(object, null, 2)}`);
    return;
  }
  window.mixpanel.track("gamepage-finish-level", {
    f_level: object.level,
    f_score: object.score,
    f_game: object.gameId,
    f_action_guid: window.funfunding.uuidv4()
  });
  store.dispatch("reportScore", object);
};

let windowEventListener = function (e) {
  switch (e.data.type) {
    case "loading":
      // this event may not arrives linear
      // e.g. not all values from 0 to 100 may be sent
      return loading(e.data.percentage);
    case "loaded":
      return playGame(e.data.url);
    case "send":
      return sendScore({
        type: e.data.label,
        level: e.data.level,
        score: e.data.score,
        gameId: window.client.gameId
      });
  }
};

export default {
  name: "App",
  created: function () {
    //////// 2) Create the parent-child communication ////////
    const eventMethod  = window.addEventListener
        ? "addEventListener"
        : "attachEvent";
    const eventer      = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    // Listen to message from child window
    window.removeEventListener(messageEvent, windowEventListener, false);
    eventer(messageEvent, windowEventListener, false);
  },
  components: {},

  data: () => ({})
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Fredoka+One&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
* a {
  text-decoration: none;
}

body{
  height: 100vh!important;
  overflow: hidden;
}

#app {
  overflow: hidden !important;
}

#gameiframe{
  /*height: 93vh;*/
  border: none;
}

#game-frame, .game-content:first-child, .game-content, div.v-application div.v-application--wrap:first-child, div.v-application div.v-application--wrap, div.v-application{
  /*height: 100%!important;*/
  border: none;
}

.all-tabs .v-tabs-bar {
  background-color: transparent !important;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.all-tabs .v-tabs-bar__content {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.all-tabs .v-tabs-slider-wrapper {
  height: 3px !important;
  background-color: white !important;
  color: white;
  border-radius: 30px !important;
  border: 1px solid white !important;
}

.lider-tabs-items .v-tab.v-tab--active {
  color: white !important;
}

.v-overlay .v-overlay__scrim {
  background-color: transparent !important;
}

.input-field input:-webkit-autofill,
.input-field input:-webkit-autofill:hover,
.input-field input:-webkit-autofill:focus,
.input-field input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #1E004E inset !important;
  -webkit-text-fill-color: #fff !important;
}

.input-field > .v-input__control > .v-input__slot:before {
  border-bottom: 2px solid #88BDF4 !important;
  margin-top: 5px !important;
}

.input-field > .v-input__control > .v-input__slot {
  padding-bottom: 5px;
}

.input-field input, .theme--light.v-input textarea {
  color: #fff !important;
  text-align: center;
}

.input-field > .v-input__control > .v-input__slot .focus-visible {
  border-color: #88BDF4 !important;
}

.green-btn .v-btn__content, .letsStart-btn .v-btn__content {
  color: #fff !important;
}
.v-btn__content {
  letter-spacing: initial;
}
.green-btn {
  font-family: Fredoka One !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 36px !important;
  line-height: 44px !important;
  color: #fff !important;
}
#input-12 {
  padding: 0 !important;
  max-height: none !important;
}
#input-12::placeholder {
  color: #88BDF4 !important;
  font-size: 20px;
}
.leader-content .v-window.v-item-group .v-window__container--is-active {
  background-color: #373e7b !important;
  transition: 0ms;
}

</style>
