import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
// import router from './router';
import EventBus from './event-bus';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
    },
    mutations: {
        SET_USER(state, data) {
            state.competition = data.competition;
            state.donates = data.donates;
            state.leaderboard = data.leaderboard;
            state.team = data.team;
            state.userId = data.userId;
            state.nextLevelPopup = data.popupAtLevel;
        },
        SET_LEADERBOARD(state, data) {
            state.competition = data.competition;
            state.donates = data.donates;
            state.leaderboard = data.leaderboard;
            state.sharePopup.currentPoints += (data.incrementedScoreDelta || 0);
            state.inspirationPopup.currentPoints += (data.incrementedScoreDelta || 0);
            if (data.showPopup) {
                state.sharePopup.currentPoints = 0;
                state.sharePopup.nextPoints = 700;
            }
            if (data.showInspirationPopup) {
                state.inspirationPopup.quoteIndex = (state.inspirationPopup.quoteIndex + 1) % 15;
                state.inspirationPopup.currentPoints = 0;
                state.inspirationPopup.nextPoints = 350;
            }
            if (data.nextLevelPopup) {
                state.nextLevelPopup = data.nextLevelPopup;
            }
        },
        SET_TEAM_NAME(state, data) {
            state.teamName = data
        },
        SET_TOURNAMENT_DATA(state, data) {
            state.competition = data.competition;
            state.tournamentData = data.tournament;
            state.tournamentEnd = data.tournament.endTime;
            state.tournamentStart = data.tournament.startTime;
        },
        setNickname(state, data) {
            state.nickName = data
        },
        SET_HIGHSCORES(state, data) {
            state.highscores = data
        },
        SET_TEAM_ID(state, data) {
            state.teamId = data.team._id
            state.userId = data.userId
            state.shortId = data.team.shortId
        },
        initWelcomePage(state) {
            state.showNickModal = undefined
            state.errTxt        = ''
            state.nickName      = ''
        },
        initialiseStore() {
            let initState = {
                version: '1.9',
                team: null,
                competition: null,
                donates: null,
                userId: null,
                leaderboard: null,
                sharePopup: {
                    currentPoints: 0,
                    nextPoints: 0
                },
                inspirationPopup: {
                    quoteIndex: 0,
                    currentPoints: 0,
                    nextPoints: 150
                }
            };
            let storeState = localStorage.getItem('store');
            if (storeState) {
                storeState = JSON.parse(storeState);
                if (storeState.version === initState.version) {
                    initState = storeState;
                }
            }

            localStorage.setItem('store', JSON.stringify(initState));
            this.replaceState(initState);

            if (!initState.userId) {
                // asaf - todo: read from url
                setTimeout(() => {
                    registerNewUser(this.state.teamId, this.state.userId, this.commit);
                }, 1000)
            } else {
				window.mixpanel.register({
                    f_user_id: initState.userId,
                    f_subteam_group: initState.popupAtLevel,
                    f_team_name: initState.team.teamName,
                });
				registerPollfish(initState.userId);
            }
        },
    },
    actions: {
        setNickName({state, commit}, nickname) {
            const userId = state.userId
            state.errTxt = ''
            return axios.post(`https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/user/?userId=${userId}&nickname=${nickname}`, {
                params: {
                    userId,
                    nickname
                }
            })
                .then((response) => {
                    if (!response.data.error) {
                        commit('setNickname', response.data.user.nickname);
                        state.showNickModal = false
                    } else {
                        state.errTxt = 'This nickname is already taken. Please choose a different nickname.'

                    }
                    return response.data
                })
                .catch((error) => {
                    console.log(error)
                    return error
                })
        },
        getNickName({state, commit}) {
            const userId = state.userId
            return axios.get(`https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/user/?userId=${userId}`, {
                params: {
                    userId
                }
            })
                .then((response) => {
                    if (response.data.user.nickname !== undefined) {
                        commit('setNickname', response.data.user.nickname)
                        state.showNickModal = false
                    } else {
                        state.showNickModal = true
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },

        reportScore({ commit, state }, payload) {
            // axios.post('https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/actions/', {
            //     params: {
            //         userId: state.userId,
            //         gameId: payload.gameId,
            //         score: payload.score,
            //         level: payload.level || 'none'
            //     }
            // })
            let myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            let raw = JSON.stringify({
                "userId": state.userId,
                "gameId": payload.gameId,
                "score": payload.score,
                "level": payload.level,
                "platform": 'hr',
            });

            // var raw = JSON.stringify({"userId":`${state.userId}`,"gameId": `${payload.gameId}`,"score":`${payload.score}`,"level":`${payload.level}`});

            let requestOptions = {
                method: 'POST',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch("https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/actions/", requestOptions)
                .then(response => response.text())
                .then((res) =>  {
                    let data = JSON.parse(res)
                    let currentNewPoints1 = state.sharePopup.currentPoints + (data.incrementedScoreDelta || 0);
                    let currentNewPoints2 = state.inspirationPopup.currentPoints + (data.incrementedScoreDelta || 0);
                    data.showPopup = (currentNewPoints1 >= state.sharePopup.nextPoints);
                    data.showInspirationPopup = (currentNewPoints2 >= state.inspirationPopup.nextPoints);
                    if (data.showInspirationPopup) {
                        data.inspirationQuoteIndex = state.inspirationPopup.quoteIndex || 0;
                    }
                    // if (parseInt(payload.level) === parseInt(state.nextLevelPopup)) {
                    //     data.currentLevel = payload.level;
                    //     data.showPopup = true;
                    //     data.nextLevelPopup = state.nextLevelPopup > 0 ? state.nextLevelPopup + 5 : 0;
                    // }
                    EventBus.$emit("new_personal_score", data);
                    commit('SET_LEADERBOARD', data);
                })
                .catch(error => console.log('error', error));

                // .then(({ data }) => {
                //     let currentNewPoints1 = state.sharePopup.currentPoints + (data.incrementedScoreDelta || 0);
                //     let currentNewPoints2 = state.inspirationPopup.currentPoints + (data.incrementedScoreDelta || 0);
                //     data.showPopup = (currentNewPoints1 >= state.sharePopup.nextPoints);
                //     data.showInspirationPopup = (currentNewPoints2 >= state.inspirationPopup.nextPoints);
                //     if (data.showInspirationPopup) {
                //         data.inspirationQuoteIndex = state.inspirationPopup.quoteIndex || 0;
                //     }
                //     // if (parseInt(payload.level) === parseInt(state.nextLevelPopup)) {
                //     //     data.currentLevel = payload.level;
                //     //     data.showPopup = true;
                //     //     data.nextLevelPopup = state.nextLevelPopup > 0 ? state.nextLevelPopup + 5 : 0;
                //     // }
                //     EventBus.$emit("new_personal_score", data);
                //     commit('SET_LEADERBOARD', data);
                // });
        },
        demoAddScore({ commit, state }) {
            let data = {
                competition: {
                    logo: "",
                    totalDonations: 1000,
                    endTime: "2020-03-22T08:00:00Z"
                },
                donates: [500, 300, 200],
                leaderboard: [
                    { "_id": "5e68e5c2e7179a17e215b3bb", teamName: "Europe", supporting: "Community Award", score: 247212, supportingUrl: "https://brands-rousers.org/", currentTour: true, membersCount: 283, leaderboardTitle: "Community Award" },
                    { "_id": "5e68e5b4e7179a17e215b3b9", teamName: "Latin America", supporting: "Catalyst Award", score: 42146, supportingUrl: "https://brands-rousers.org/", currentTour: true, membersCount: 118, leaderboardTitle: "Catalyst Award" },
                    { "_id": "5e68e5d1e7179a17e215b3be", teamName: "Northern America", supporting: "@ Work Award", score: 28513, supportingUrl: "https://brands-rousers.org/", currentTour: true, membersCount: 43, leaderboardTitle: "@ Work Award" },
                    { "_id": "5e68e5dee7179a17e215b3c8", teamName: "Brazil", supporting: "Award in Education", score: 4073, supportingUrl: "https://brands-rousers.org/", currentTour: true, membersCount: 9, leaderboardTitle: "Award in Education" },
                    { "_id": "5e68e5eae7179a17e215b3c9", teamName: "Asia", supporting: "Award and Social Impact", score: 0, דupportingUrl: "https://brands-rousers.org/", currentTour: true, membersCount: 10, leaderboardTitle: "Award and Social Impact" }
                ],
                incrementedScoreDelta: 350
            };
            let currentNewPoints1 = state.sharePopup.currentPoints + (data.incrementedScoreDelta || 0);
            let currentNewPoints2 = state.inspirationPopup.currentPoints + (data.incrementedScoreDelta || 0);
            data.showPopup = (currentNewPoints1 >= state.sharePopup.nextPoints);
            data.showInspirationPopup = (currentNewPoints2 >= state.inspirationPopup.nextPoints);
            if (data.showInspirationPopup) {
                data.inspirationQuoteIndex = state.inspirationPopup.quoteIndex || 0;
            }
            EventBus.$emit("new_personal_score", data);
            commit('SET_LEADERBOARD', data);
        },
        loadHighScores({commit}, tournamentId) {
          return axios.get(`https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/highscore/`, {
              params: {
                  tournamentId
              }
          })
              .then((response) => {
                  commit('SET_HIGHSCORES', response.data.highscore);

              })
              .catch((error) => {
                  console.log(error)
              })
        },
        getTeamId({commit}, shortId) {
            return axios.get('https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/teams', {
                params: {
                    shortId
                }
            })
                .then((response) => {
                    localStorage.setItem('userId',response.data.userId)
                    commit('SET_TEAM_ID', response.data)
                    commit('SET_TEAM_NAME', response.data.team.teamName)
                    // commit('SET_TEAM_DATA', response.data.team)
                    commit('SET_USER', response.data);
                    return response.data
                    // commit('SET_TEAM_NAME', response.data.team.teamName)
                })
                .catch((error) => {
                    console.log(error)
                })
        },
         getLeaderboardRequest1({ commit }, teamId ) {
            if(!teamId) {
                teamId = this.state.teamId
            }
            return axios.get('https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/leaderboard/', {
                params: {
                    teamId
                }
            }).then(({ data }) => {
                commit('SET_TOURNAMENT_DATA', data)
                commit('SET_LEADERBOARD', data);
                localStorage.setItem('startTournament', data.tournament.startTime)
                return data
            }).catch(() => {
                console.log('ERROR')
            });
        }
    },
});

const registerPollfish = (userId) => {
    let pollfishConfig = {
        api_key: "558048d3-fa0f-403d-8dc9-3b3d10fa4c08",
        debug: false,
        ready: window.pollfishReady,
        offerwall: true,
        uuid: userId
    };
    window.Pollfish.start(pollfishConfig);
};

const registerNewUser = (teamId, userId, commit) => {
    return axios.get('https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/teams', {
        params: {
            teamId: teamId,
            refUserId: userId,
        }
    })
        .then(({ data }) => {
            commit('SET_USER', data);
			window.mixpanel.register({
                f_user_id: data.userId,
                f_subteam_group: data.popupAtLevel,
                f_team_name: data.team.teamName
            });
            // window.history.replaceState(
            //     {},
            //     "FunFunding",
            //     `/${this.state.shortId}`
            // );
            window.mixpanel.track("mainpage-view", {
                f_action_guid: window.funfunding.uuidv4()
            });
            registerPollfish(data.userId);
        });
}

// const getLeaderboardRequest = (teamId, commit) => {
//     axios.get('https://g5qw1wbqr3.execute-api.eu-west-1.amazonaws.com/v1/leaderboard/', {
//         params: {
//             teamId
//         }
//     }).then(({ data }) => {
//         commit('SET_LEADERBOARD', data);
//     }).catch(() => {
//         console.log('ERROR')
//     });

//     const getLeaderboardRequest = (teamId, commit) => {
//     axios.get('https://34mt0dgx92.execute-api.eu-west-1.amazonaws.com/v1_0/leaderboard/', {
//         params: {
//             teamId
//         }
//     }).then(({ data }) => {
//         commit('SET_LEADERBOARD', data);
//     });
// };
