<template>
  <div>
    <LeaderboardHeader :showButton="true"/>
    <LeaderboardBody/>
  </div>
</template>

<script>
import LeaderboardHeader from "../components/LeaderboardHeader.vue";
import LeaderboardBody from "../components//LeaderboardBody.vue";

export default {
  data() {
    return {};
  },
  components: {
    LeaderboardBody,
    LeaderboardHeader
  },
  mounted: function () {
    window.mixpanel.track("leaderboard-view", {
      f_action_guid: window.funfunding.uuidv4()
    });
  }
};
</script>
