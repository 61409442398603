<template>
  <div>
    <v-dialog v-model="openThankYou" width="500">
      <v-card class="thankYou-card">
        <v-btn
            icon
            dark
            @click="closePopup()"
            color="#fff"
            style="font-size:20px; background-color: #ffad61"
            class="close-popup"
        >X
        </v-btn>
        <v-card-text class="inspire-text">
          <div class="thank-you">Thank You for Supporting 'NGO name'</div>
          <div class="thank-you-message">{{ message }}</div>
          <div class="donate-btn" @click="donate">Donate</div>
        </v-card-text>
        <v-img src="../assets/images/background.png"></v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message: ' We appreciate your contribution. \n' +
          'If you like what we are doing and wish to further support us please press  the \'Donate\' button.'
    }
  },
  methods: {
    donate() {
      console.log("Donate")
    },
    closePopup() {
      this.openThankYou = false;
      this.$emit('closeThankYou', this.openThankYou)
    }
  },
  props: [
    "openThankYou"
  ]
}
</script>

<style scoped>
.thankYou-card {
  border-radius: 25px !important;
  border: 1px solid #fff;
}

.thank-you {
  position: relative;
  top: 65px;
  max-width: 300px;
  font-family: Fredoka One;
  font-size: 23px;
  margin: 0 auto;
  text-align: center;
  line-height: normal;
  color: #5a78d4;
}

.close-popup {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 32px;
  height: 32px;
}

.thank-you-message {
  max-width: 340px;
  margin: 0 auto;
  text-align: center;
  font-family: Fredoka One;
  font-size: 16px;
  color: #88bdf4;
  position: relative;
  top: 85px;
}

.donate-btn {
  margin: 0 auto;
  width: 70%;
  margin-top: 50px;
  font-family: Fredoka One;
  font-size: 22px;
  color: #5a78d4;
  position: relative;
  top: 75px;
  z-index: 30 !important;
  border-radius: 28px;
  border: solid 3px #5a78d4;
  padding: 15px 0;
  text-align: center;
  cursor: pointer;
  transition: 0.4s;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}
</style>
