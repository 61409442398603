<template>
  <div>
<!--    <div class="black-border" :class="{'close-black-border': !modalStatus}">-->
<!--      <div class="border-content">-->
<!--        <div class="F-image"></div>-->
<!--        <div class="border-text">-->
<!--          <h4>Add FunFunding to Home screen</h4>-->
<!--        </div>-->
<!--        <v-btn class="add-button" @click="addToHomeScreen()">Add</v-btn>-->
<!--        <v-btn icon dark color="white" class="x-button" @click="closeModal()">-->
<!--          <v-icon class="close-button">mdi-close</v-icon>-->
<!--        </v-btn>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "BlackModal",
  data() {
    return {
      modalStatus: true,
    };
  },
  // mounted: function () {
  //   this.modalStatus = window.deferredPrompt ? true : false;
  // },
  // methods: {
  //   closeModal() {
  //     this.modalStatus = false;
  //     window.mixpanel.track("gamepage-addtohome-close-click", {
  //       f_action_guid: window.funfunding.uuidv4(),
  //     });
  //   },
  //   addToHomeScreen() {
  //     window.mixpanel.track("gamepage-addtohome-click", {
  //       f_action_guid: window.funfunding.uuidv4(),
  //     });
  //     if (window.deferredPrompt) {
  //       window.deferredPrompt.prompt();
  //     }
  //   },
  // },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");

.black-border {
  width: 100%;
  height: 80px;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 21px 16px;
  transform: translateY(0);
  visibility: visible;
  transition: 0.5s;
}

.close-black-border {
  transform: translateY(100%);
  visibility: hidden;
}

.border-content {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.F-image {
  width: 40px;
  height: 40px;
  background-color: white;
  background-position: center;
  border-radius: 50%;
  background-image: url("../assets/images/logo.png");
  margin-right: 4px;
}

.border-text {
  width: 128px;
  height: 50px;
  color: white;
  font-family: Montserrat;
  font-size: 14px;
  display: flex;
  align-items: center;
}

.border-text h4 {
  font-weight: 100 !important;
}

.add-button {
  background-color: #535bb6 !important;
  border-radius: 50px;
  color: white !important;
  width: 90px !important;
  height: 39px !important;
  text-transform: inherit;
  font-family: "Lato", sans-serif;
}

.close-button {
  font-weight: 900 !important;
}

.x-button {
  width: 16px;
  height: 16px;
  margin: -9px -7px 0 15px;
}
</style>
