<template>
  <div>
    <v-container class="grey lighten-5" style="font-weight:400;">
      <v-row no-gutters>
        <v-col cols="12" sm="12">
          <div class="header-title-wrapper">
            <div v-if="showButton" @click="goBack" class="images-back"></div>
            <span>Leaderboard</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" sm="12">
          <div class="google-logo">
            <div class="google-container">Sponsored by</div>
            <div class="googleLogo"></div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["showButton"],
  methods: {
    goBack() {
      this.$router.go(-1);
      window.mixpanel.track("leaderboardpage-backbutton-click", {
        f_action_guid: window.funfunding.uuidv4()
      });
    }
  }
};
</script>

<style scoped>
.header-title-wrapper {
  position: relative;
  font-family: Fredoka One;
  font-size: 20px;
  color: #808dd3;
  width: 100%;
  text-align: center;
}

.images-back {
  background-image: url("../assets/images/back.png");
  background-repeat: no-repeat;
  width: 13px;
  height: 22px;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.google-container {
  font-family: Montserrat;
  font-size: 10px;
  line-height: 1;
  letter-spacing: normal;
  color: #cccccc;
  margin-top: 20px;
}

.google-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3px;
}

.google-logo > .googleLogo {
  background-image: url("../assets/images/funfunding.png");
  background-repeat: no-repeat;
  background-size: contain;
  width: 200px;
  height: 50px;
  margin-top: 4px;
}
</style>
