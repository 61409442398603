import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store';
import VueMultianalytics from 'vue-multianalytics';

Vue.config.productionTip = true

let gaConfig = {
  appName: 'FunfundingApp', // Mandatory
  appVersion: '0.1', // Mandatory
  trackingId: 'UA-153625580-1', // Mandatory
  debug: true, // Whether or not display console logs debugs (optional)
  options: {
    'clientId': '35009a79-1a05-49d7-b876-2b884d0f825b',
    'siteSpeedSampleRate': 10
  }
}

let mixpanelConfig = {
  token: '1ff1983f06c4befbc7dc79124a12b994'
}

Vue.use(VueMultianalytics, {
  modules: {
    ga: gaConfig,
    mixpanel: mixpanelConfig
  },
  routing: {
    vueRouter: router, //  Pass the router instance to automatically sync with router (optional)
    preferredProperty: 'name', // By default 'path' and related with vueRouter (optional)
    ignoredViews: [], // Views that will not be tracked
    ignoredModules: [] // Modules that will not send route change events. The event sent will be this.$ma.trackView({viewName: 'homepage'}, ['ga'])
  }
});

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  beforeCreate() {
    this.$store.commit('initialiseStore');
    this.$store.subscribe((mutation, state) => {
      localStorage.setItem('store', JSON.stringify(state));
    });
  },
}).$mount('#app')
