import Vue from 'vue';
import Router from 'vue-router';
import LeaderBoard from "./views/Leaderboard";
import MainPage from "./views/MainPage";
import GamePage from "./views/GamePage"

Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        // {
        //     path: "/",
        //     component: MainPage,
        //     name: "MainPage"
        // },
        {
            path: "/:shortId",
            component: MainPage,
            name: "MainPage"
        },
        {
            path: "/leaderboard",
            component: LeaderBoard,
            name: "LeaderBoard"
        },
        {
            path: "/game/:gameId",
            component: GamePage,
            name: "GamePage"
        }

    ]
});
